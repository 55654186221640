<template>
  <div class="readmore" :class="[moreClass, mobileShow ? 'mobile-show p-3' : '']">
    <p v-html="translatedFixedText" v-if="fixedText" class="p-0 m-0"></p>
    <VueReadMoreSmooth :text="$t('product-page.read-more')" :text-less="$t('product-page.read-less')" :lines="lines">
      <p v-html="translatedMessage" :class="[textAlign, 'hotel_description']"></p>
    </VueReadMoreSmooth>
  </div>
</template>
<script>
import VueReadMoreSmooth from 'vue-read-more-smooth';
import { mapGetters } from 'vuex';
import { translateFormattedText } from '@/utils/translate';

export default {
  components: { VueReadMoreSmooth },
  props: {
    message: {
      type: String,
      default: '',
    },
    mobileShow: {
      type: Boolean,
      default: false,
    },
    fixedText: {
      type: String,
      default: '',
    },
    textAlign: {
      type: String,
      default: 'text-right',
    },
    moreClass: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapGetters({
      lang: 'GET_LANGUAGE',
    }),
  },
  data() {
    return {
      lines: 3,
      translatedMessage: '',
      translatedFixedText: '',
    };
  },
  watch: {
    async lang() {
      this.translatedMessage = await translateFormattedText(this.message, this.lang);
      this.translatedFixedText = await translateFormattedText(this.fixedText, this.lang);
    },
  },
  async beforeMount() {
    this.lines = this.fixedText ? 1 : 3;
    this.translatedMessage = await translateFormattedText(this.message, this.lang);
    this.translatedFixedText = await translateFormattedText(this.fixedText, this.lang);
  },
};
</script>

<style>
  .button-read-more span{
    font-weight: bold;
  }
</style>

<style scoped>
  .readmore {
    padding-left: 10px;
    min-height: 20px;
    min-width: 20px;
    font-size: 14px;
    position: relative;
    margin: 2px 1px;
  }

  .bold-text .hotel_description {
    font-weight: 600;
  }

  @media (max-width: 479px) {
    .readmore {
      display: none;
    }
    .readmore.mobile-show {
      display: block;
      font-size: 12px;
    }
  }
</style>
